<!--
 * @Description: 订单子项目
 * @Author: zhang zhen
 * @Date: 2023-02-14 11:30:36
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-05-25 10:28:34
 * @FilePath: /page-sass/src/views/workSpace/modules/orderCardItem.vue
-->
<template>
  <div class="infoCardItem">
    <div class="top-info">
      <span>订单编号：{{ orderInfo.orderNo || '' }}</span>
      <span>提交时间：{{ orderInfo.createTime | formatDate }}</span>
    </div>
    <div class="info-box">
      <h3 class="orderName">{{ orderInfo.orderTitle || '' }}</h3>

      <div class="right-row">
        <div class="orderCount">
          <span class="label">状态：</span>
          <a-tag color="#f50" v-if="orderInfo.status == 0">
            待签署
          </a-tag>
          <a-tag color="#f50" v-if="orderInfo.status == 1">
            已签署
          </a-tag>
          <a-tag color="#2db7f5" v-else-if="orderInfo.status == 2">
            已付款
          </a-tag>
          <a-tag color="#108ee9" v-else-if="orderInfo.status == 3">
            生产中
          </a-tag>
          <a-tag color="#108ee9" v-else-if="orderInfo.status == 4">
            已发货
          </a-tag>
          <a-tag color="#108ee9" v-else-if="orderInfo.status == 5">
            运输中
          </a-tag>
          <a-tag color="#108ee9" v-else-if="orderInfo.status == 6">
            已收货
          </a-tag>
          <a-tag color="#87d068" v-else-if="orderInfo.status == 7">
            已完结
          </a-tag>
          <a-tag color="orange" v-else-if="orderInfo.status == -1">
            已取消
          </a-tag>
        </div>
        <a-button type="primary" @click="handleSwitchTab">查看详情</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'infoCardItem',
  props: {
    orderInfo: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleSwitchTab() {
      const { orderTitle, orderNo } = this.orderInfo
      this.$router.push(this.setUserInfo().tradeIdentity == 0 || this.setUserInfo().tradeIdentity == 1 ?`/orderManagement/orderDetails?orderTitle=${orderTitle}&orderNo=${orderNo}` : `/orderManagement/orderDetailsForUser?orderTitle=${orderTitle}&orderNo=${orderNo}`)
    },
  },
}
</script>

<style lang="less" scoped>
.infoCardItem {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 15px;
  .top-info {
    margin-bottom: 8px;
    color: #a8aab2;
    font-size: 13px;
    font-weight: 500;
    span {
      margin-right: 90px;
    }
  }
  .info-box {
    background: #f7f7f7;
    padding: 15px;
    border-radius: 4px;
    .flexLayout();
    .right-row {
      .flexLayout();
    }
    .orderCount {
      font-size: 13px;
      margin-right: 40px;
      .label {
        color: #9f9b9b;
      }
      .num {
        color: #090b12;
      }
    }
  }
}
</style>
