<!--
 * @Description: main description
 * @Author: zhang zhen
 * @Date: 2023-02-14 10:45:00
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-05-25 11:01:30
 * @FilePath: /page-sass/src/views/workSpace/modules/infoCardItem.vue
-->
<template>
  <div class="infoCardItem">
    <div class="top-info"><span>需求编号：{{orderInfo.purchaseNo || ''}}</span><span>提交时间：{{orderInfo.createTime | formatDate}}</span></div>
    <div class="info-box">
      <h3 class="orderName">{{ orderInfo.purchaseTitle || '' }}</h3>

      <div class="orderCount">
        <span class="label">关联订单数：</span>
        <span class="num">{{ orderInfo.participantCount || 0 }}</span>
      </div>
      <div class="orderCount">
        <span class="label">状态：</span>
        <a-tag color="#5E5E66" v-if="orderInfo.status == 0">
          已取消
        </a-tag>
        <a-tag color="#2db7f5" v-else-if="orderInfo.status == 1">
          已发布
        </a-tag>
        <a-tag color="#87d068" v-else-if="orderInfo.status == 2">
          已完成
        </a-tag>
        <a-tag color="#2db7f5" v-else-if="orderInfo.status == 3">
          待发布
        </a-tag>
        <a-tag color="#f00" v-else-if="orderInfo.status == -1">
          已删除
        </a-tag>
      </div>
      <a-button type="primary" @click="handleJumpPage">查看详情</a-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'infoCardItem',
  props: {
    orderInfo: {
      type: Object,
      default: () => ({
      })
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleJumpPage() {
      const { purchaseTitle, purchaseId, businesStatus, status, purchaseNo } = this.orderInfo
      this.$router.push(this.setUserInfo().tradeIdentity == 0 || this.setUserInfo().tradeIdentity == 1 ?`/requirement/forFacilitatorDetails?purchaseTitle=${purchaseTitle}&purchaseId=${purchaseId}&formType=${businesStatus == '0' ? 'product' : businesStatus == '1' ? 'package' : 'plan'}` : `/requirement/ForUserAndPurchaseDetails?purchaseId=${purchaseId}&purchaseTitle=${purchaseTitle}&purchaseNo=${purchaseNo}&activeTab=BasicInfo&formType=${businesStatus == '0' ? 'product' : businesStatus == '1' ? 'package' : 'plan'}&status=${status}`)
    }
  },
}
</script>

<style lang="less" scoped>
.infoCardItem {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 15px;
  .top-info {
    margin-bottom: 8px;
    color: #a8aab2;
    font-size: 13px;
    font-weight: 500;
    span {
      margin-right: 90px;
    }
  }
  .info-box {
    background: #f7f7f7;
    padding: 15px;
    border-radius: 4px;
    .flexLayout();
    .orderCount {
      font-size: 13px;
      .label {
        color: #9f9b9b;
      }
      .num {
        color: #090b12;
      }
    }
  }
}
</style>
